import React, { Component } from "react";
import AppContext from "./AppContext";
import APP_CONFIG from "../configs/app-config";
import { HttpService, GlobalService } from "../services";

let dealerData = APP_CONFIG.defaultDealer;
const DEALER_BASE_URL = APP_CONFIG.apiUrl;

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerData: dealerData,
      dealerNumber: this.getDealerParams("dealerNumber="),
      activeRequests: [],
      selectedCatIndex: -1,
      weightingData: {},
      sortedCatalogCars: {},
      isMobile: false,
    };

    this.providerActions = {
      selectCategory: this.selectCategory.bind(this),
    };

    // Load tracking scripts
    this.rootEl = document.getElementById("kkpAngebote");
    this.windowResizeHandler = this.windowResizeHandler.bind(this);
  }

  componentDidMount() {
    this.updateMobileState();
    this.getDealerWeightingData("offer").then((weightingData) => {
      this.setState({ weightingData });
    });
    window.addEventListener("resize", this.windowResizeHandler, false);
    this.getDealerData(this.state.dealerNumber);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResizeHandler);
  }

  setActiveRequestState(requestId, isActive) {
    const { activeRequests } = this.state;
    if (isActive && activeRequests.indexOf(requestId) < 0) {
      this.setState({ activeRequests: [...activeRequests, requestId] });
      return;
    }
    if (!isActive && activeRequests.indexOf(requestId) > -1) {
      this.setState({
        activeRequests: activeRequests.filter((id) => id !== requestId),
      });
    }
  }

  updateMobileState() {
    if (this.rootEl && this.rootEl.offsetWidth <= 500 && !this.state.isMobile) {
      this.setState({ isMobile: true });
      return;
    }
    if (this.rootEl && this.rootEl.offsetWidth > 500 && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }

  selectCategory(selectedCatIndex) {
    this.setState({ selectedCatIndex });
    let categoryToScrollTo =
      APP_CONFIG.navigationItems[selectedCatIndex].toLowerCase();
    switch (categoryToScrollTo) {
      case "dacia spring":
        categoryToScrollTo = "dacia_spring";
        break;
      case "dacia sandero":
        categoryToScrollTo = "dacia_sandero";
        break;
      case "dacia jogger":
        categoryToScrollTo = "dacia_jogger";
        break;
      case "dacia sandero stepway":
        categoryToScrollTo = "dacia_sandero_stepway";
        break;
      case "dacia lodgy":
        categoryToScrollTo = "dacia_lodgy";
        break;
      case "dacia duster":
        categoryToScrollTo = "dacia_duster";
        break;
      default:
        break;
    }
    GlobalService.scrollToCategory(categoryToScrollTo);
  }

  windowResizeHandler() {
    this.updateMobileState();
  }

  getDealerParams(whichParam) {
    if (window.location.href.split(whichParam).length > 1) {
      return window.location.href.split(whichParam)[1].split("&")[0];
    }
    return "";
  }

  sortCatalogCarsByCategories(allCatalogCars = []) {
    let catalogCarsByCatories = {};
    if (allCatalogCars.length) {
      Object.keys(APP_CONFIG.categories).map((category) => {
        catalogCarsByCatories[category] = {};
        catalogCarsByCatories[category].headline =
          APP_CONFIG.categories[category].headline;
        let carCategory = category;
        //todo create a mapping for this
        switch (category) {
          case "dacia_spring":
            carCategory = "dacia spring";
            break;
          case "dacia_sandero":
            carCategory = "dacia sandero";
            break;
          case "dacia_sandero_stepway":
            carCategory = "dacia sandero stepway";
            break;
          case "dacia_lodgy":
            carCategory = "dacia lodgy";
            break;
          case "dacia_duster":
            carCategory = "dacia duster";
            break;
          case "dacia_jogger":
            carCategory = "dacia jogger";
            break;
          default:
            break;
        }
        catalogCarsByCatories[category].cars = allCatalogCars.filter(
          (offerObj) => {
            // Parse possible BBCodes
            Object.keys(offerObj).map((prop) => {
              if (offerObj[prop]) {
                offerObj[prop] = GlobalService.bbCodeParser(
                  offerObj[prop].toString()
                );
              }
              return offerObj[prop];
            });
            return offerObj.carCategory.toLowerCase() === carCategory;
          }
        );
        if (catalogCarsByCatories[category].cars.length === 0) {
          APP_CONFIG.navigationItems = APP_CONFIG.navigationItems.filter(
            (el) => el.toLowerCase() !== carCategory
          );
          delete catalogCarsByCatories[category];
        }

        return category;
      });
      this.setState({ sortedCatalogCars: catalogCarsByCatories });
    }
  }

  async getDealerWeightingData(whichWeighting) {
    return await HttpService.request({
      method: "GET",
      url: `${DEALER_BASE_URL}/offer`,
    });
  }

  getDealerData(dealerNum) {
    if (!dealerNum) {
      return;
    }
    this.setActiveRequestState("getDealerData", true);
    HttpService.request({
      method: "GET",
      url: `${DEALER_BASE_URL}/dealer/${dealerNum}`,
    }).then(
      (dealerData) => {
        let weightingData = JSON.parse(
          JSON.stringify(this.state.weightingData)
        );

        this.getDealerWeightingData("offer").then((wData) => {
          weightingData = wData;
          this.sortCatalogCarsByCategories(weightingData);
          this.setState({ dealerData, weightingData });
          this.setActiveRequestState("getDealerData", false);
        });
      },
      (err) => {
        this.setActiveRequestState("getDealerData", false);
        console.log(err);
      }
    );
  }

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          activeRequests: this.state.activeRequests,
          dealerData: this.state.dealerData,
          dealerNumber: this.state.dealerNumber,
          isMobile: this.state.isMobile,
          selectedCatIndex: this.state.selectedCatIndex,
          sortedCatalogCars: this.state.sortedCatalogCars,
          actions: this.providerActions,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
