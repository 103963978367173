import axios from 'axios';
export class HttpService {

    static request(requestConfig, commonHeaderObj) {
        const options = {
            method: requestConfig.method.toUpperCase(),
            url: requestConfig.url
        };

        switch (requestConfig.method.toUpperCase()) {
        case 'POST':
            options.data = requestConfig.data ? requestConfig.data : {};
            break;
        case 'GET':
            break;
        case 'DELETE':
            break;
        case 'PATCH':
            break;
        default:
            break;
        }

        return new Promise((resolve, reject) => {
            let httpInstance = axios.create({headers: {
                common: {Accept: "application/json, text/plain, */*"}}});
            if (commonHeaderObj && Object.keys(commonHeaderObj).length) {
                Object.keys(commonHeaderObj).map(prop => {
                    httpInstance.defaults.headers['common'][`${prop}`] = commonHeaderObj[prop];
                    return prop;
                });
            }
            httpInstance(options).then(response => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
}