import React from 'react';
import './NavigationBar.scss';
import {Dropdown} from "../index";

function NavigationBar({navItems = [], categoryIndex = -1, categoryNavFn}) {
    const desktopNavItems = navItems.map((item, idx) => {
        return <div className={`navigation__desktop__item ${categoryIndex === idx ? 'active-category' : ''}`}
                    key={`${item}_${idx}`}
                    id={`nav_${item.toLowerCase()}`} onClick={() => categoryNavFn(idx)}>
            <span>{item}</span>
        </div>;
    });
    return (
        <div className={`navigation`}>
            <div className={'navigation__desktop'}>
                {desktopNavItems}
            </div>
            <div className={'navigation__mobile'}>
                <Dropdown id={'kkpNavigationDropdown'}
                          options={navItems}
                          selectedIndex={categoryIndex}
                          selectOptionCallback={categoryNavFn} />
            </div>
        </div>
    );
}

export default NavigationBar;