import React from 'react';
import PropTypes from 'prop-types';
import './dropdown.scss';
import { Flyout } from '../';

class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
        this.dropDownRef = React.createRef();
        this.innerSelectElement = null;
        this.closeDropDown = this.closeDropDown.bind(this);
        this.dropDownInnerChangeEvent = null;
        this.dropdownChangeHandler = this.dropdownChangeHandler.bind(this);
    }

    componentDidMount() {
        this.innerSelectElement = this.dropDownRef.current.getElementsByTagName('select')[0];
        this.dropDownInnerChangeEvent = new Event('selectchange');
        this.innerSelectElement.addEventListener('selectchange', this.dropdownChangeHandler);
        document.addEventListener('click', this.closeDropDown);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeDropDown);
        if (this.innerSelectElement) {
            this.innerSelectElement.removeEventListener('selectchange', this.dropdownChangeHandler);
        }
    }

    selectOption(e) {
        const { selectOptionCallback } = this.props;
        /*when an item is clicked, update the original select box,
                and the selected item box:*/
        let clickedOption = e.currentTarget,
            clickedOptValue = clickedOption.getAttribute('value');
        // Update inner select element
        this.innerSelectElement.selectedIndex = parseInt(clickedOptValue);
        this.innerSelectElement.dispatchEvent(this.dropDownInnerChangeEvent);
        if (typeof selectOptionCallback === 'function') {
            selectOptionCallback(this.innerSelectElement.selectedIndex);
        }
        this.setState({isOpen: false});
    }

    dropDownButtonClick() {
        this.setState({isOpen: !this.state.isOpen});
    }

    closeDropDown(event) {
        const target = event.target;
        if (this.dropDownRef.current && !this.dropDownRef.current.contains(target)) {
            this.setState({isOpen: false});
        }
    }

    dropdownChangeHandler(e) {
        if (typeof this.props.onChangeHandler === 'function') {
            this.props.onChangeHandler(e);
        }
    }


    render() {
        const { options, id, selectedIndex, name } = this.props;
        const dropDownOptions = options.map((opt, idx) => {
            return <option value={opt} key={`${opt}_${idx}`}>{opt}</option>;
        });
        const dropDownDisplayOptions = options.map((opt, index) => {
            if (selectedIndex === index) {
                return null;
            }
            return <div className={`rleads-dropdown__items__item ${!opt ? 'is-empty' : ''} ${selectedIndex === index ? 'rleads-dropdown__selected-option' : ''}`}
                key={`item_${opt}_${index}`}
                onClick={this.selectOption.bind(this)} value={index}>
                <span className={'rleads-dropdown__items__item__text'}>{opt}</span>
            </div>;
        });

        return (
            <div className={'rleads-dropdown'} id={id} ref={this.dropDownRef}>
                <select name={name}>
                    {dropDownOptions}
                </select>
                <div className={`rleads-dropdown__selected ${this.state.isOpen ? 'is-open' : ''}`} onClick={this.dropDownButtonClick.bind(this)}>
                    { selectedIndex === -1 ? <span>Bitte auswählen ...</span> : null }
                    {options[selectedIndex]}
                </div>
                <Flyout show={this.state.isOpen}>
                    <div className={'rleads-dropdown__items'}>
                        {dropDownDisplayOptions}
                    </div>
                </Flyout>
            </div>
        );
    }
}

export default Dropdown;

Dropdown.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number.isRequired
};