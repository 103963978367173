import React, { Component } from "react";
import {
  AppHeader,
  AppFooter,
  Hero,
  ServiceTeaser,
  ServiceInsurance,
  OfferCategories,
  ServiceVertrage,
} from "./components";
import AppContext from "./context/AppContext";

class App extends Component {
  static contextType = AppContext;

  render() {
    const { dealerData, selectedCatIndex, sortedCatalogCars, actions } =
      this.context;
    return (
      <div className="App" id={`wrapper`}>
        <AppHeader
          dealerData={dealerData}
          selectedCategoryIndex={selectedCatIndex}
          selectCategoryFn={actions.selectCategory}
        />
        <Hero />
        <div className={"App__content wrap"}>
          <OfferCategories
            dealerData={dealerData}
            sortedCatalogsWithCars={sortedCatalogCars}
          />
          <ServiceTeaser />
          <ServiceInsurance />
          <ServiceVertrage />
        </div>
        <AppFooter dealerData={dealerData} />
      </div>
    );
  }
}

export default App;
