import React from "react";
import "./AppFooter.scss";
// import markerSvg from "./../../assets/icons/toolbar/marker.svg";
// import newsSvg from "./../../assets/icons/toolbar/news.svg";
// import testdriveSvg from "./../../assets/icons/toolbar/testdrive.svg";

function AppFooter({ dealerData }) {
  return (
    <div className={"app-footer"} id={"footer"}>
      <div className="footer-content wrap">
        <div className="contact">
          <h3>
            <strong>TERMIN? FRAGE? BERATUNG?</strong>
          </h3>
          <p className="outro">
            Was immer wir für Sie tun können, wir tun es gern. Rufen Sie uns
            einfach an und lassen Sie uns besprechen, wie wir Ihnen am besten
            helfen können!
          </p>
          <p className="service-phone">
            Unsere Service-Nummer lautet:
            <br />
            <span>{dealerData.dealerTel}</span>
          </p>
          <p className="address">
            <span>{dealerData.dealerName}</span>
            <br />
            <span>{dealerData.dealerStreet}</span>,
            <span> {dealerData.dealerZip}</span>
            <span> {dealerData.dealerCity}</span>
          </p>
        </div>
        <div className="buttons">
          <div className="group-btn">
            <a
              href="https://www.dacia.de/top-angebote-gewerbekunden.html"
              className="btn-cta"
              target="_blank"
              rel="noopener noreferrer"
            >
              ZU DEN MODELLEN
            </a>
            <a href={`tel:${dealerData.dealerTel}`} className="btn-cta">
              RUFEN SIE UNS AN
            </a>
            <a
              href="https://www.dacia.de/konfigurieren.html"
              className="btn-cta"
              target="_blank"
              rel="noopener noreferrer"
            >
              ZUM KONFIGURATOR
            </a>
          </div>
        </div>
      </div>
      <div className="footer-content wrap">
        <p className="micro">Abbildungen zeigen Sonderausstattung.</p>
        <p className="micro">* Reichweite im kombinierten WLTP-Zyklus in km.</p>
        <p className="micro">
          ** Je nach Ausstattungsniveau serienmäßig oder optional erhältlich.
        </p>
        <p className="micro">*** Nur in Verbindung mit Electric 65.</p>
        <p className="micro">
          <sup>1</sup> Dacia Spring Essential Electric 45: Leasing:
          Fahrzeugpreis: 12.497 € netto. 590 € netto Sonderzahlung, mtl. Rate:
          109 € netto. Laufzeit: 36 Monate. Laufleistung: 15.000 km. Zzgl.
          Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>2</sup> Gold Leasing: Dacia Spring Essential Electric 45:
          Fahrzeugpreis 13.132 € netto inkl. Dacia Full Service Vertrag
          <sup>3</sup> im Wert von 634 € netto. 580 € netto Sonderzahlung.
          Laufzeit: 36 Monate. Gesamtlaufleistung 15.000 km. Monatsrate: 129 €
          netto inkl. GAP-Versicherung<sup>4</sup> i. H. v. 18,46 €.
          Gesamtbetrag: 5.224 € netto. Zzgl. Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>3</sup> Dacia Full Service Vertrag, bestehend aus einer
          Garantieverlängerung der Renault Deutschland AG<sup>5</sup> sowie der
          Abdeckung aller Kosten der vorgeschriebenen Wartungs- und
          Verschleißarbeiten, Hauptuntersuchung (HU) sowie Mobilitätsgarantie
          für die Vertragsdauer von 36 Monaten bzw. 30.000 km ab Erstzulassung
          gemäß Vertragsbedingungen. HU nach § 29 StVZO. Die HU wird nur durch
          zugelassene Prüfer und zu den von der zuständigen Prüforganisation
          festgelegten Preisen durchgeführt.
        </p>
        <p className="micro">
          <sup>4</sup> GAP-Versicherung inklusive: Die GAP-Versicherung sichert
          gegen finanzielle Verluste ab, wenn das Fahrzeug während der
          Leasinglaufzeit gestohlen wird oder einen Totalschaden erleidet.
          Versicherung über die RCI Versicherungs-Service GmbH, Jagenbergstr. 1,
          41468 Neuss. Versicherer: RCI Insurance Limited, Level 3 Mercury
          Tower, The Exchange Financial & Business Centre, Elia Zammit Street,
          St. Julian’s, STJ 3155, Malta. Es gelten die allgemeinen
          Versicherungsbedingungen.
        </p>
        <p className="micro">
          <sup>5</sup> Garantiegeberin ist die Renault Deutschland AG. Die
          Inanspruchnahme der gesetzlichen Gewährleistungsansprüche ist
          unentgeltlich. Diese Ansprüche bestehen unabhängig von der Garantie
          und werden durch diese nicht eingeschränkt.
        </p>
        <p className="micro">
          <sup>6</sup> Dacia Sandero Essential SCe 65: Leasing: Fahrzeugpreis:
          9.666 € netto. 400 € netto Sonderzahlung, mtl. Rate: 89 € netto.
          Laufzeit: 36 Monate. Laufleistung: 30.000 km. Zzgl. Überführung und
          gesetzl. USt.
        </p>
        <p className="micro">
          <sup>7</sup> Gold Leasing: Dacia Sandero Essential SCe 65:
          Fahrzeugpreis 10.507 € netto inkl. Dacia Full Service Vertrag
          <sup>3</sup> im Wert von 840 € netto. 520 € netto Sonderzahlung.
          Laufzeit: 36 Monate. Gesamtlaufleistung 30.000 km. Monatsrate: 109 €
          netto inkl. GAP-Versicherung<sup>4</sup> i. H. v. 12,89 €.
          Gesamtbetrag: 4.444 € netto. Zzgl. Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>8</sup> Dacia Sandero Stepway Essential TCe 90: Leasing:
          Fahrzeugpreis: 12.187 € netto. 640 € netto Sonderzahlung, mtl. Rate:
          119 € netto. Laufzeit: 36 Monate. Laufleistung: 30.000 km. Zzgl.
          Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>9</sup> Gold Leasing: Dacia Sandero Stepway Essential TCe 90:
          Fahrzeugpreis 13.036 € netto inkl. Dacia Full Service Vertrag
          <sup>3</sup> im Wert von 849 € netto. 450 € netto Sonderzahlung.
          Laufzeit: 36 Monate. Gesamtlaufleistung 30.000 km. Monatsrate: 149 €
          netto inkl. GAP-Versicherung<sup>4</sup> i. H. v. 16,17 €.
          Gesamtbetrag: 5.814 € netto. Zzgl. Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>10</sup> Dacia Duster Essential ECO-G 100: Leasing:
          Fahrzeugpreis: 15.924 € netto. 420 € netto Sonderzahlung, mtl. Rate:
          149 € netto. Laufzeit: 36 Monate. Laufleistung: 30.000 km. Zzgl.
          Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>11</sup> Gold Leasing: Dacia Duster Essential ECO-G 100:
          Fahrzeugpreis 16.752 € netto inkl. Dacia Full Service Vertrag
          <sup>3</sup> im Wert von 828 € netto. 500 € netto Sonderzahlung.
          Laufzeit: 36 Monate. Gesamtlaufleistung 30.000 km. Monatsrate: 169 €
          netto inkl. GAP-Versicherung<sup>4</sup> i. H. v. 20,70 €.
          Gesamtbetrag: 6.584 € netto. Zzgl. Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>12</sup> Dacia Jogger Essential ECO-G 100: Leasing:
          Fahrzeugpreis: 14.440 € netto. 560 € netto Sonderzahlung, mtl. Rate:
          99 € netto. Laufzeit: 36 Monate. Laufleistung: 30.000 km. Zzgl.
          Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          <sup>13</sup> Gold Leasing: Dacia Jogger Essential ECO-G 100:
          Fahrzeugpreis 15.369 € netto inkl. Dacia Full Service Vertrag
          <sup>3</sup> im Wert von 929 € netto. 380 € netto Sonderzahlung.
          Laufzeit: 36 Monate. Gesamtlaufleistung 30.000 km. Monatsrate: 129 €
          netto inkl. GAP-Versicherung<sup>4</sup> i. H. v. 19,55 €.
          Gesamtbetrag: 5.024 € netto. Zzgl. Überführung und gesetzl. USt.
        </p>
        <p className="micro">
          Kilometer-Leasingangebote für Gewerbekunden von Mobilize Financial
          Services, Geschäftsbereich der RCI Banque S.A. Niederlassung
          Deutschland, Jagenbergstr. 1, 41468 Neuss, zzgl. Überführungskosten
          und gesetzliche Umsatzsteuer. Gültig für Dacia Neuwagen und
          Leasingverträge bis zum 30.11.2024.
        </p>
      </div>
      {/*<div className="footer-tool">
        <ul className="menu-tool">
          <li className="menu-tool-item">
            <a
              href="https://www.dacia.de/haendlersuche.html"
              className="link"
              title="Händlersuche"
            >
              <picture>
                <img src={markerSvg} alt="" />
              </picture>
              <p className="link-container">
                <span className="link-title">Händlersuche</span>
                <span className="link-body">
                  Finden Sie einen Händler in Ihrer Nähe
                </span>
              </p>
            </a>
          </li>
          <li className="menu-tool-item">
            <a
              href="https://www.dacia.de/kontakt/newsletter.html"
              className="link"
              title="Dacia Newsletter"
            >
              <picture>
                <img src={newsSvg} alt="" />
              </picture>
              <p className="link-container">
                <span className="link-title">Dacia Newsletter</span>
                <span className="link-body">Bleiben Sie informiert!</span>
              </p>
            </a>
          </li>
          <li className="menu-tool-item">
            <a
              href="https://www.dacia.de/kontakt.html"
              className="link"
              title="Probefahrt vereinbaren"
            >
              <picture>
                <img src={testdriveSvg} alt="" />
              </picture>
              <p className="link-container">
                <span className="link-title">Probefahrt vereinbaren</span>
                <span className="link-body">
                  Testen Sie Ihren Wunsch-Dacia auf der Straße
                </span>
              </p>
            </a>
          </li>
          <li className="menu-tool-item">
            <a
              href="https://www.dacia.de/?rleads=broschuere&intern=1"
              className="link"
              title="Broschüre bestellen"
            >
              <picture>
                <img src={newsSvg} alt="" />
              </picture>
              <p className="link-container">
                <span className="link-title">Broschüre bestellen</span>
                <span className="link-body">
                  Informieren Sie sich über Ihren Wunsch-Dacia
                </span>
              </p>
            </a>
          </li>
        </ul>
      </div> */}
      <div className="footer-menu">
        <div className="footer-menu-content">
          <ul className="footer-menu-item info">
            <li>
              <a href="https://www.dacia.de/rechtliche-hinweise.html">
                Rechtliche Hinweise
              </a>
            </li>
            <li>
              <a href="https://www.dacia.de/datenschutz.html">Datenschutz</a>
            </li>
            <li>
              <a href="https://www.dacia.de/impressum.html">Impressum</a>
            </li>
            <li>
              <a href="https://www.dacia.de/cookies.html">Cookies</a>
            </li>
          </ul>
          <ul className="footer-menu-item copyright">
            <li className="no-link">
              &copy; Dacia 2017 - {new Date().getFullYear()}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AppFooter;
