import React, { useState } from 'react';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './OfferCategories.scss';
import {CarOffer} from "../index";

function OfferCategories ({dealerData, sortedCatalogsWithCars}) {
    const [viewMore, setViewMore] = useState(false);
    const [energyMore, setEnergyMore] = useState(false);
   
    const toggleElementClass = (whichEl, elementRef) => {
        if (elementRef && elementRef.current) {
            switch (whichEl) {
                case 'moreOffer':
                    setViewMore((!viewMore));
                    break;
                case 'moreEnergy':
                    setEnergyMore((!energyMore));
                    break;
                default:
                    break;
            }
        }
    };

console.log(sortedCatalogsWithCars);

    return (
        <div className={'categories'}>
            {Object.keys(sortedCatalogsWithCars).map((cat, idx) => {
                if (!sortedCatalogsWithCars[cat].cars.length) {
                    return null;
                }
                return <div className={'categories__category'} key={`${cat}_${idx}`} id={cat}>
                    {/* <div className="categories__category__title">
                        <h3>{sortedCatalogsWithCars[cat].headline}</h3>
                    </div> */}
                    <div className={`categories__category__offers`}>
                        {/* <Slider {...settings}> */}
                            {sortedCatalogsWithCars[cat].cars.map((cOffer, index) => {
                                return <CarOffer offerData={cOffer}
                                                 dealerData={dealerData}
                                                 key={`category_${idx}_offer${index}`}
                                                 viewMore={viewMore}
                                                 energyMore={energyMore}
                                                 toggleFn={toggleElementClass}/>
                            })}
                        {/* </Slider> */}
                    </div>
                </div>;
            })}
        </div>
    );
}

export default OfferCategories;