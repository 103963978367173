import React from 'react';
import { ServiceVertrageItem } from '..';
import './ServiceVertrage.scss';

export default function ServiceVertrage() {
    return (
        <div className='service-vertrage'>
            <ServiceVertrageItem />
        </div>
    )
}