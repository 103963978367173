const apiUrl =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://rleads.renault.de/api/dacia/kkp"
    : "https://stage-rleads.renault.de/api/dacia/kkp";

const appConfig = {
  apiUrl,
  defaultDealer: {
    dealerWeighting: "Strong",
    dealerNumber: "43830",
    dealerName: "Autohaus Mustermann",
    dealerStreet: "Musterstraße 123",
    dealerZip: "12345",
    dealerCity: "Musterstadt",
    dealerTel: "0123/456789",
    dealerFax: "+49 (0) 12345678 99",
    dealerLogoFilename: "L44370",
    dealerSignatureText: "Muster Signature",
    dealerSignatureFilename: "U44370",
  },
  navigationItems: [
    "Dacia Spring",
    "Dacia Sandero",
    "Dacia Sandero Stepway",
    "Dacia Duster",
    "Dacia Jogger",
  ],
  categories: {
    dacia_spring: {
      headline: "Dacia Spring",
      offers: [],
    },
    dacia_sandero: {
      headline: "Dacia Sandero",
      offers: [],
    },
    dacia_sandero_stepway: {
      headline: "Dacia Sandero Stepway",
      offers: [],
    },
    dacia_duster: {
      headline: "Dacia Duster",
      offers: [],
    },
    dacia_jogger: {
      headline: "Dacia Jogger",
      offers: [],
    },
  },
};

export default appConfig;
