import React from 'react';
import PropTypes from 'prop-types';
// import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {CSSTransition} from 'react-transition-group';

class Flyout extends React.Component {
    constructor(props) {
        super(props);
        this.flyoutRef = React.createRef();
        this.parentElement = null;
        this.docBody = null;
        this.scrollElement = null;

        this.flyoutPositionStyle = {
            position: 'fixed',
            zIndex: 1090,
            visibility: 'hidden',
            top: 0,
            left: 0,
            width: 0
        };

        this.scrollHandler = this.scrollHandler.bind(this);
        this.windowResizeHandler = this.windowResizeHandler.bind(this);
    }


    componentDidMount() {
        this.parentElement = this.flyoutRef.current.parentElement;
        this.docBody = document.getElementsByTagName('body')[0];
        this.scrollElement = document;
        if (this.scrollElement) {
            this.scrollElement.removeEventListener('scroll', this.scrollHandler);
        }
        window.addEventListener('resize', this.windowResizeHandler);
    }


    componentDidUpdate() {
        if (this.props.show) {
            this.scrollElement.addEventListener('scroll', this.scrollHandler);
        } else {
            if (this.scrollElement) {
                this.scrollElement.removeEventListener('scroll', this.scrollHandler);
            }
        }
        if (this.docBody) {
            this.updatePosition(this.flyoutRef, this.docBody.clientHeight);
        }
    }

    componentWillUnmount() {
        if (this.scrollElement) {
            this.scrollElement.removeEventListener('scroll', this.scrollHandler);
        }
        window.removeEventListener('resize', this.windowResizeHandler);
    }


    scrollHandler() {
        this.updatePosition(this.flyoutRef, this.docBody.clientHeight);
    }


    windowResizeHandler() {
        this.updatePosition(this.flyoutRef, this.docBody.clientHeight);
    }


    updatePosition(flyoutEl, modalElInnerHeight) {
        if (flyoutEl.current) {
            // First of all reset flyout height
            flyoutEl.current.style.height = 'auto';
            const flyoutDOMEl = flyoutEl.current,
                flyoutHeight = flyoutDOMEl.offsetHeight,
                flyoutParentDimensions = this.parentElement.getBoundingClientRect(),
                flyoutParentHeight = this.parentElement.offsetHeight,
                modalElBoundingRect = this.docBody.getBoundingClientRect(),
                fytParentModalRect = {
                    top: flyoutParentDimensions.top, // - modalElBoundingRect.top,
                    left: flyoutParentDimensions.left, // - modalElBoundingRect.left,
                    right: flyoutParentDimensions.right, // - modalElBoundingRect.right,
                    bottom: modalElBoundingRect.bottom // - flyoutParentDimensions.bottom
                };

            const isDownPosition = (fytParentModalRect.top + flyoutParentHeight + flyoutHeight <= modalElInnerHeight);
            const isTopPosition = (flyoutHeight <= fytParentModalRect.top);
            const isForceDownPosition = (!isDownPosition && !isTopPosition);
            const elTopSize = (isDownPosition ? fytParentModalRect.top + flyoutParentHeight : isTopPosition ? (fytParentModalRect.top - flyoutHeight) : fytParentModalRect.top + flyoutParentHeight);

            if (this.props.center) {
                flyoutDOMEl.style.left = 0;
                flyoutDOMEl.style.right = 0;
                // flyoutDOMEl.style.zIndex = 1110;
                flyoutDOMEl.style.width = `calc(100% - 36px)`;
                flyoutDOMEl.style.margin = `auto`;
                flyoutDOMEl.style.visibility = `visible`;
                if (this.props.show) {
                    flyoutDOMEl.style.zIndex = 1110;
                } else {
                    flyoutDOMEl.style.zIndex = 0;
                }
                const topSize = (modalElBoundingRect.height - parseInt(getComputedStyle(flyoutDOMEl).height)) / 2;
                flyoutDOMEl.style.top = `${topSize}px`;
                return;
            }
            flyoutDOMEl.style.left = `${fytParentModalRect.left}px`;
            flyoutDOMEl.style.right = 'unset';
            // flyoutDOMEl.style.zIndex = 1090;
            flyoutDOMEl.style.top = `${elTopSize + (isDownPosition ? -2 : isTopPosition ? 1 : -2)}px`;
            flyoutDOMEl.style.width = `${flyoutParentDimensions.width}px`;
            flyoutDOMEl.style.visibility = `visible`;
            if (this.props.show) {
                flyoutDOMEl.style.zIndex = 1090;
            } else {
                flyoutDOMEl.style.zIndex = 0;
                flyoutDOMEl.style.height = 'auto';
            }
            if (isForceDownPosition) {
                if (this.props.show) {
                    flyoutDOMEl.style.height = `${fytParentModalRect.bottom}px`;
                } else {
                    flyoutDOMEl.style.height = 'auto';
                }
                flyoutDOMEl.style.overflow = 'auto';
                return;
            }
            flyoutDOMEl.style.height = `auto`;
        }
    }

    render() {

        const { children, show } = this.props;
        const flyoutContent = React.Children.toArray(children);

        const displayStyles = JSON.parse(JSON.stringify(this.flyoutPositionStyle));

        return (
            <div className={'flyout'} ref={this.flyoutRef} style={displayStyles}>
                <CSSTransition in={show}
                               classNames={'fade'}
                               unmountOnExit
                               timeout={300}>
                    <div className={'flyout__content'}>
                        {/*{show ? flyoutContent : null}*/}
                        {flyoutContent}
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default Flyout;

Flyout.propTypes = {
    show: PropTypes.bool.isRequired
};