import bbCodeParser from "js-bbcode-parser";

// Add further BBCodes that are not in available default codes \\[url=(.+?)\\](.+?)\\[/url\\]
bbCodeParser.add('\\[sup\\](.+?)\\[/sup\\]', '<sup>$1</sup>');
bbCodeParser.add('\\[sub\\](.+?)\\[/sub\\]', '<sub>$1</sub>');
bbCodeParser.add('\\[sp\\](.+?)\\[/sp\\]', '<span>$1</span>');
bbCodeParser.add('\\[price\\](.+?)\\[/price\\]', '<span class="price">$1</span>');
bbCodeParser.add('\\[br-\\]', '&shy;');
bbCodeParser.add('\\[nbsp\\]', '&nbsp;');
bbCodeParser.add('\\[mp\\](.+?)\\[/mp\\]', '<span class="multi-price">$1</span>');
bbCodeParser.add('\\[px\\](.+?)\\[/px\\]', '<span class="prefix">$1</span>');
bbCodeParser.add('\\[link=(.+?)\\](.+?)\\[/link\\]', '<a href="$1" target="_blank" rel="noopener">$2</a>');
bbCodeParser.add('\\n', '&nbsp;');

//@TODO - Exception [mp][sp]ab [price]58 €[/price] netto[sup]3[/sup][/sp] [sp][px]zzgl.[/px] [price]30 €[/price]  netto/mtl.[sup]4[/sup][/sp][/mp]

export class GlobalService {
    
    static scrollToCategory(elementId) {
        let docBody = document.scrollingElement; //.getElementsByTagName('body')[0]
        let scrollInterVal = null;
        let DOMElement = document.getElementById(elementId);
        window.DOMElement = DOMElement;
        if (DOMElement) {
            clearInterval(scrollInterVal);
            scrollInterVal = setInterval(() => {
                const bRect = DOMElement.getBoundingClientRect();
                if (bRect.top > 50) {
                    docBody.scrollTop += 50;
                } else {
                    clearInterval(scrollInterVal);
                }
            }, 10);
            // Stop interval if DOMElement is not found to avoid endless interval
            setTimeout(() => {
                if (scrollInterVal && !DOMElement) {
                    clearInterval(scrollInterVal);
                }
            }, 4000);
        }
    }

    static slideUp (e,element, duration = 400) {
        if (!element) return;
        return new Promise((resolve) => {
            element.style.height = element.offsetHeight + 'px';
            element.style.transitionProperty = `height, margin, padding`;
            element.style.transitionDuration = duration + 'ms';
            // element.offsetHeight;
            element.style.overflow = 'hidden';
            element.style.paddingTop = 0;
            element.style.paddingBottom = 0;
            element.style.marginTop = 0;
            element.style.marginBottom = 0;
            setTimeout(() => {
                element.style.height = 0;
            });
            e.currentTarget.classList.remove('is-open');
            setTimeout(() => {
                element.style.display = 'none';
                element.style.removeProperty('height');
                element.style.removeProperty('padding-top');
                element.style.removeProperty('padding-bottom');
                element.style.removeProperty('margin-top');
                element.style.removeProperty('margin-bottom');
                element.style.removeProperty('overflow');
                element.style.removeProperty('transition-duration');
                element.style.removeProperty('transition-property');
                resolve(false);
            }, duration);
        });
    }

    static slideDown (e, element, duration = 400) {
        if (!element) return;
        return new Promise(() => {
            element.style.removeProperty('display');
            let display = window.getComputedStyle(element).display;

            if (display === 'none')
                display = 'block';

            element.style.display = display;
            let height = element.offsetHeight;
            element.style.overflow = 'hidden';
            element.style.height = 0;
            element.style.paddingTop = 0;
            element.style.paddingBottom = 0;
            element.style.marginTop = 0;
            element.style.marginBottom = 0;
            // element.offsetHeight;
            element.style.transitionProperty = `height, margin, padding`;
            element.style.transitionDuration = duration + 'ms';
            setTimeout(() => {
                element.style.height = height + 'px';
            });
            element.style.removeProperty('padding-top');
            element.style.removeProperty('padding-bottom');
            element.style.removeProperty('margin-top');
            element.style.removeProperty('margin-bottom');
            e.currentTarget.classList.add('is-open');
            setTimeout(() => {
                element.style.removeProperty('height');
                element.style.removeProperty('overflow');
                element.style.removeProperty('transition-duration');
                element.style.removeProperty('transition-property');
            }, duration);
        });
    }

    static slideToggle(e, elementRef, duration = 400) {
        if (!elementRef) return;
        const element = elementRef.current;
        element.classList.toggle("mystyle");

        if (window.getComputedStyle(element).display === 'none') {
            return this.slideDown(e, element, duration);
        } else {
            return this.slideUp(e, element, duration);
        }
    }
    
    static bbCodeParser(input) {
        return bbCodeParser.parse(input);
    }
}
