import React from "react";
import "./ServiceTeaser.scss";
import img from "../../assets/images/insurance-1460x823.jpg";

function ServiceTeaser() {
  return (
    <div className={"service-teaser"}>
      <div className={"teaser-img"}>
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default ServiceTeaser;
