const globalConstants = {
  header: {
    headline: "DACIA <strong><div>HERBST&shy;ANGEBOTE</div></strong>",
    subHeadline: " Entdecken Sie die Firmenwagen von Dacia",
  },
  teaser: {
    image_Strong: "footer_teaser_image.jpg",
    image_Weak: "footer_teaser_image_weak.jpg",
    textContent: [
      {
        headline: `<h5>Der Renault Full Service:
                        <br/>alles drin, alles gut.</h5>`,
        body: `<p>Der Renault Full Service umfasst sämtliche Kosten für vorgeschriebene Wartungsarbeiten, Verschleißreparaturen, Mobilitätsgarantie sowie die Hauptuntersuchung (HU) nach § 29 StVZO. Inklusive sind auch der Austausch bzw. die Reparatur defekter mechanischer, elektrischer und elektronischer Teile. Werkstattkosten lassen sich dadurch langfristig und präzise kalkulieren.</p>
                    <p>Der Renault Full Service ist über die Renault Bank finanzierbar und kann innerhalb der ersten 12 Monate nach Erstzulassung des Renault Neufahrzeugs gemäß Vertragsbedingungen abgeschlossen werden, allerdings nur bis zur ersten fälligen Wartung.</p>`,
      },
      {
        headline: `<h5>Effizientes Flottenmanagement:
                        <br/>die Renault Fleet Services.</h5>`,
        body: `<p>Widmen Sie sich voll und ganz Ihrem Kerngeschäft – die Renault Fleet Services befreien Sie von allen lästigen Aufgaben, die das Management einer Firmenflotte mit sich bringt. Vom Technik-Service für Wartungsarbeiten und Verschleißreparaturen über den Reifen-Service bis hin zum Kfz-Steuer- und Versicherungs-Service und die Rundum-Absicherung für Sie und Ihren Fuhrpark kümmern wir uns für Sie um alle Pflichten, die Ihre Dienstfahrzeuge betreffen.</p>
                    <p>Dabei sind alle Serviceleistungen beliebig mit unseren attraktiven Leasingangeboten kombinierbar. Sprechen Sie uns einfach an, wir machen Ihnen gerne ein individuelles Angebot.</p>`,
      },
      {
        headline: `<h5>Maßgeschneiderte Funktionalität:
                        <br/>die Renault Auf- und Umbaupartner.</h5>`,
        body: `<p>Jedes unserer Renault Nutzfahrzeuge erhalten Sie auf Wunsch in zahlreichen Varianten. Je nach Modell können Sie zwischen unterschiedlichen Radständen und Fahrzeuglängen, Front- oder Heckantrieb, Doppel- oder Einzelkabine und zahlreichen Ausstattungsvarianten wählen.</p>
                    <p>Sollten Sie jedoch noch speziellere Anforderungen haben, machen wir auch diese gerne in Zusammenarbeit mit unseren zertifizierten Auf- und Umbaupartnern für Sie möglich. Ob besondere Abschlepp-, Kipp- oder Ladungssicherungssysteme, mobile Verkaufsstände, Kühl- oder Lebensmitteltransporte – unser Anspruch ist es, maßgeschneiderte Fahrzeuglösungen ganz nach Ihren Wünschen zu realisieren.</p>`,
      },
    ],
  },
  teaser_insurance: {
    headline: "Dacia <strong>Versicherungen</strong>",
    subheadline: "ALLES AUS EINER HAND",
    btnCtaInsurances: "",
    insurances: [
      {
        headline: "",
        body: "Genießen Sie ein entspanntes Fahrvergnügen in Ihrem neuen Dacia – mit den besonderen Versicherungsangeboten von Mobilize Financial Services. Produkte, die speziell auf Ihre Bedürfnisse zugeschnitten sind und weit über die Kfz-Haftpflicht- und Kaskoversicherung hinausgehen, wie z. B. die GAP-Versicherung, die bei Totalschaden und Diebstahl greift, die innovative Mini-Kasko SECURPLUS oder das Autotagegeld, um im Schadenfall mobil zu bleiben.",
      },
    ],
    offer: {
      name: "SECURPLUS",
      price_pretext: "bereits ab",
      price: '<span class="price">0,30 €<sup>2</sup></span> am Tag',
      benefits: [
        "Europaweiter Schutz",
        "Absicherung auch bei Parkplatzschäden oder Kollisionen mit Mauern, Pfosten, Zäunen o.Ä.",
        "Schutz bei selbst verschuldeten Verkehrsunfällen",
        "Schonung des Schadensfreiheitsrabatts im Schadenfall",
        "Absicherung von bis zu zwei Schadensereignissen pro Jahr",
        "Auszahlung, sobald die Kaskoversicherung die Übernahme bestätigt",
      ],
    },
    footnodes: [
      "Der Bonus gilt nur in Verbindung mit dem Produkt GAP-Versicherung Bonus.",
      "Bsp. für SECURPLUS 300 (8,99 € mtl.): SECURPLUS ist ein Produkt des Renault Versicherungs-Service, eines Geschäftsbereichs der RCI Versicherungs-Service GmbH, Jagenbergstraße 1, 41468 Neuss. Es gelten die allgemeinen Versicherungsbedingungen für SECURPLUS. Versicherer ist die MMA IARD Assurance Mutuelles und MMA IARD S.A., 14 Boulevard Marie et Alexandre Oyon, 72030 Le Mans cédex 9 (Frankreich).",
    ],
  },
  serviceVertrage: {
    headline: "Dacia <strong>Service-Verträge</strong>",
    vertrage: {
      headline: "Unser Service für Ihre Fahrzeuge",
      subheadline: "",
      body: "Mit unseren Service-Verträgen sind Sie vor unangenehmen finanziellen Überraschungen sicher und können z. B. die anfallenden Werkstattkosten für Ihr Fahrzeug weitaus besser kalkulieren. Wählen Sie einfach den Ihrem Bedarf entsprechenden Service-Vertrag aus unserem Angebot und sparen Sie Kosten, Aufwand, Stress und Sorgen.",
      subindice: "<sup>1</sup> Gemäß Vertragsbedingungen.",
      cta: "VOM SERVICE PROFITIEREN",
    },
  },
  footer: {},
  elektrifizierter_text: `Elektrifizierter Antrieb`,
  interesse_geweckt_text: `<p>Wir haben Ihr Interesse geweckt?<br/>Fragen Sie Ihren Renault Partner nach aktuellen Angeboten.</p>`,
};

export default globalConstants;
