import React from "react";
import heroImage from "../../assets/images/header-titel_1920x823.jpg";

function Hero() {
  return (
    <div className="hero">
      <img src={heroImage} alt="" />
    </div>
  );
}

export default Hero;
