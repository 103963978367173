import React from "react";
import GLOBAL_CONSTANTS from "../../constants/global-constants";
import "./ServiceInsurance.scss";

function ServiceInsurance() {
  const teaserInsuranceContent = GLOBAL_CONSTANTS.teaser_insurance;
  return (
    <div className={"service-insurance"}>
      <div className={"heading"}>
        <h4
          dangerouslySetInnerHTML={{
            __html: `${teaserInsuranceContent.subheadline}`,
          }}
        />
        <h3
          dangerouslySetInnerHTML={{
            __html: `${teaserInsuranceContent.headline}`,
          }}
        />
      </div>
      <div className={"insurances"}>
        <div className={"insurances__items"}>
          {teaserInsuranceContent.insurances.map((item) => {
            return (
              <div className={"insurances__item"} key={item.headline}>
                <h5 dangerouslySetInnerHTML={{ __html: `${item.headline}` }} />
                <p dangerouslySetInnerHTML={{ __html: `${item.body}` }} />
              </div>
            );
          })}
        </div>
        {teaserInsuranceContent.btnCtaInsurances.length > 0 && (
          <a
            href="https://www.dacia.de/finanzierung/versicherung.html"
            className="btn-cta"
            target="_blank"
            rel="noopener noreferrer"
          >
            {teaserInsuranceContent.btnCtaInsurances}
          </a>
        )}
      </div>
    </div>
  );
}

export default ServiceInsurance;
