import React from "react";
import GLOBAL_CONSTANTS from "../../constants/global-constants";
import "./ServiceVertrageItem.scss";
import serviceImg from "./../../assets/images/service-623x350.jpg";

export default function ServiceVertrageItem() {
  const vertrage = GLOBAL_CONSTANTS.serviceVertrage.vertrage;
  return (
    <div className={`vertrage`}>
      <div className={`vertrage__slide`}>
        <div className="vertrage-img">
          <div>
            <img src={serviceImg} alt="" />
          </div>
        </div>

        <div className="vertrage-content vertrage-text">
          <h4 dangerouslySetInnerHTML={{ __html: vertrage.subheadline }}></h4>
          <h5
            className="subheadline"
            dangerouslySetInnerHTML={{ __html: vertrage.headline }}
          ></h5>
          <h5
            className="teaser"
            dangerouslySetInnerHTML={{ __html: vertrage.body }}
          ></h5>
          <a
            href="https://www.dacia.de/wartung/service.html"
            className="btn-cta mb-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            {vertrage.cta}
          </a>
        </div>
      </div>
    </div>
  );
}
