import React from "react";
import GLOBAL_CONSTANTS from "../../constants/global-constants";
import "./CarOffer.scss";
const IMG_BASE_URL = `${process.env.PUBLIC_URL}/images/`;

function CarOffer(props) {
  const { offerData, dealerData } = props;
  if (!offerData || !Object.keys(offerData).length) {
    return <div>NO OFFER DATA</div>;
  }

  let viewMoreRef = React.createRef(),
    energyMoreRef = React.createRef();

  const interesseGewecktText = GLOBAL_CONSTANTS.interesse_geweckt_text;
  const hasOfferPrice = offerData.hasOfferPrice.toLowerCase() === "ja";
  return (
    <div className={`offer`}>
      <div
        className={`offer__slide ${
          offerData.hasWarranty3 &&
          offerData.hasWarranty3.toLowerCase() === "ja"
            ? "has-warranty-3"
            : ""
        } ${
          offerData.hasWarranty5 &&
          offerData.hasWarranty5.toLowerCase() === "ja"
            ? "has-warranty-5"
            : ""
        } ${
          offerData.hasPlug && offerData.hasPlug.toLowerCase() === "ja"
            ? "has-plug"
            : ""
        }`}
      >
        <div className="offer-img">
          <div>
            <img src={`${IMG_BASE_URL}cars/${offerData.carImage}.jpg`} alt="" />
          </div>
        </div>
        <div className="offer-content offer-text">
          {/* {carElectric ? <span className={'is-electric'} dangerouslySetInnerHTML={{__html: elektrifizierterText}}></span> : null } */}
          {offerData.headline ? (
            <h5 dangerouslySetInnerHTML={{ __html: offerData.headline }}></h5>
          ) : null}
          {offerData.subHeadline ? (
            <h4
              className="subheadline"
              dangerouslySetInnerHTML={{ __html: offerData.subHeadline }}
            ></h4>
          ) : null}

          {offerData.teaserText1 ? (
            <h5
              className="teaser"
              dangerouslySetInnerHTML={{ __html: offerData.teaserText1 }}
            ></h5>
          ) : null}
          {offerData.modelName ? (
            <p
              className="modelName"
              dangerouslySetInnerHTML={{ __html: offerData.modelName }}
            ></p>
          ) : null}
          {offerData.teaserText2 ? (
            <h5
              className="teaser"
              dangerouslySetInnerHTML={{ __html: offerData.teaserText2 }}
            ></h5>
          ) : null}
          <ul>
            {offerData.feature1 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature1 }}></li>
            ) : null}
            {offerData.feature2 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature2 }}></li>
            ) : null}
            {offerData.feature3 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature3 }}></li>
            ) : null}
            {offerData.feature4 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature4 }}></li>
            ) : null}
            {offerData.feature5 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature5 }}></li>
            ) : null}
            {offerData.feature6 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature6 }}></li>
            ) : null}
            {offerData.feature7 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature7 }}></li>
            ) : null}
            {offerData.feature8 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature8 }}></li>
            ) : null}
            {offerData.feature9 ? (
              <li dangerouslySetInnerHTML={{ __html: offerData.feature9 }}></li>
            ) : null}
          </ul>
          {hasOfferPrice && offerData.offerPrice ? (
            <p
              dangerouslySetInnerHTML={{ __html: offerData.offerPrice }}
              className="price-rate"
            ></p>
          ) : null}
          {hasOfferPrice && offerData.offerPrice1 ? (
            <p
              dangerouslySetInnerHTML={{ __html: offerData.offerPrice1 }}
              className="price-rate-bold"
            ></p>
          ) : null}
          {hasOfferPrice && offerData.offerPrice2 ? (
            <p
              dangerouslySetInnerHTML={{ __html: offerData.offerPrice2 }}
              className="price-rate-small price-rate-bold"
            ></p>
          ) : null}
          {hasOfferPrice &&
          (offerData.contractFeature1 ||
            offerData.contractFeature2 ||
            offerData.contractFeature3 ||
            offerData.contractFeature4 ||
            offerData.contractFeature5) ? (
            <div className="data-inclusive">
              <ul>
                {offerData.contractFeature1 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature1,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature2 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature2,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature3 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature3,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature4 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature4,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature5 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature5,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature6 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature6,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature7 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature7,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature8 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature8,
                    }}
                  ></li>
                ) : null}
                {offerData.contractFeature9 ? (
                  <li
                    className="list-item-plus"
                    dangerouslySetInnerHTML={{
                      __html: offerData.contractFeature9,
                    }}
                  ></li>
                ) : null}
              </ul>
              {offerData.contractFeaturePrice ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: offerData.contractFeaturePrice,
                  }}
                ></span>
              ) : null}
            </div>
          ) : null}
          {!hasOfferPrice ? (
            <div
              className="data-interesse"
              dangerouslySetInnerHTML={{ __html: `${interesseGewecktText}` }}
            ></div>
          ) : null}

          {/*{interesseGewecktText ? <div className="data-interesse" dangerouslySetInnerHTML={{__html: interesseGewecktText}}></div> : null }*/}
          {offerData.dealerInfoText ? (
            <div
              className="data-descr"
              dangerouslySetInnerHTML={{ __html: offerData.dealerInfoText }}
            ></div>
          ) : null}
          <div className="separator"></div>
          {offerData.conumptionValuesIntro ? (
            <h5
              className="teaser"
              dangerouslySetInnerHTML={{
                __html: offerData.conumptionValuesIntro,
              }}
            ></h5>
          ) : null}
          <a
            href={`tel:${dealerData.dealerTel}`}
            className={`btn-cta`}
            style={{ maxWidth: "288px" }}
          >
            JETZT ANGEBOT TELEFONISCH SICHERN
          </a>
          <div
            className={`offer-expandable offerMoreFeatures sm`}
            ref={viewMoreRef}
          >
            <div className="expandable-holder">
              {offerData.featureLegalText || offerData.featureDescription1 ? (
                <div className="expand-list">
                  <ul>
                    {offerData.featureDescription1 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription1,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription2 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription2,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription3 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription3,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription4 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription4,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription5 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription5,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription6 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription6,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription7 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription7,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription8 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription8,
                        }}
                      ></li>
                    ) : null}
                    {offerData.featureDescription9 ? (
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offerData.featureDescription9,
                        }}
                      ></li>
                    ) : null}
                  </ul>
                  {offerData.featureLegalText ? (
                    <p
                      className="micro"
                      dangerouslySetInnerHTML={{
                        __html: offerData.featureLegalText,
                      }}
                    ></p>
                  ) : null}
                </div>
              ) : null}
              {offerData.featureImage1 || offerData.featureImage2 ? (
                <div className="expand-images">
                  {offerData.featureImage1 ? (
                    <figure>
                      {offerData.featureImage1 ? (
                        <img
                          src={`${IMG_BASE_URL}cars/${offerData.featureImage1}.jpg`}
                          alt=""
                        />
                      ) : null}
                      {offerData.featureImageText1 ? (
                        <figcaption
                          dangerouslySetInnerHTML={{
                            __html: offerData.featureImageText1,
                          }}
                        ></figcaption>
                      ) : null}
                    </figure>
                  ) : null}
                  {offerData.featureImage2 ? (
                    <figure>
                      {offerData.featureImage2 ? (
                        <img
                          src={`${IMG_BASE_URL}cars/${offerData.featureImage2}`}
                          alt=""
                        />
                      ) : null}
                      {offerData.featureImageText2 ? (
                        <figcaption
                          dangerouslySetInnerHTML={{
                            __html: offerData.featureImageText2,
                          }}
                        ></figcaption>
                      ) : null}
                    </figure>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="offer-legals">
          {offerData.legalSideNotes1 ? (
            <p
              className="micro"
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes1 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes2 ? (
            <p
              className="micro"
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes2 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes3 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes3 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes4 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes4 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes5 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes5 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes6 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes6 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes7 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes7 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes8 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes8 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes9 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes9 }}
            ></p>
          ) : null}
          {offerData.legalSideNotes10 ? (
            <p
              className={"micro"}
              dangerouslySetInnerHTML={{ __html: offerData.legalSideNotes10 }}
            ></p>
          ) : null}
        </div>
        <div
          className={`offer-expandable-energie moreEnergyFeature`}
          ref={energyMoreRef}
        >
          <div className="expandable-holder">
            <div className="expand-images">
              {offerData.energyEfficiency ? (
                <p
                  className="micro"
                  dangerouslySetInnerHTML={{
                    __html: offerData.energyEfficiency,
                  }}
                ></p>
              ) : null}
            </div>
            <div className="expand-list">
              {offerData.co2Efficency && (
                <img
                  src={`${IMG_BASE_URL}energie/as24-service-envkv-label_${offerData.co2Efficency.toLowerCase()}.jpg`}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarOffer;
