import React from "react";
import "./AppHeader.scss";
import APP_CONFIG from "../../configs/app-config";
import GLOBAL_CONSTANTS from "../../constants/global-constants";
import { NavigationBar } from "../index";

function AppHeader({ dealerData, selectCategoryFn, selectedCategoryIndex }) {
  const headerTexts = GLOBAL_CONSTANTS.header;
  return (
    <div className={`app-header`} id={"header"}>
      <div className="top">
        <div className="renault-logo">
          <a href="https://www.dacia.de" title="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 180 20"
              className="DaciaMainHeaderV2__logoSvg"
              style={{ display: "block", width: "100%", fill: "currentcolor" }}
            >
              <path d="M127.109 19.62h7.678V0h-7.678v19.62zM88.459 1.06 80.62 9.01c-.28.302-.431.54-.431.8 0 .259.15.496.431.799l7.838 7.952c.82.821 1.382 1.059 2.59 1.059h25.823v-6.634H93.036L89.668 9.81l3.368-3.176h23.836V0H91.05c-1.21 0-1.77.238-2.591 1.059zM28.413 18.562l7.838-7.952c.28-.303.431-.54.431-.8 0-.259-.15-.497-.431-.8l-7.838-7.95C27.593.238 27.031 0 25.823 0H0v6.634h23.836l3.368 3.176-3.368 3.177H0v6.634h25.822c1.21 0 1.77-.238 2.591-1.059zm141.605 1.059H180L161.173.448c-.3-.299-.536-.448-.794-.448-.257 0-.494.15-.794.448L140.758 19.62h9.897l9.66-9.939 9.703 9.939zm-102.37 0h9.982L58.804.448c-.3-.299-.537-.448-.795-.448-.257 0-.493.15-.794.448L38.39 19.62h9.896l9.66-9.939 9.703 9.939z"></path>
            </svg>
          </a>
        </div>
        <p className="intro abstand">
          <span>{dealerData.dealerName}</span>
        </p>
      </div>
      <div className="heading">
        <h2 dangerouslySetInnerHTML={{ __html: headerTexts.headline }}></h2>
        <h3>{headerTexts.subHeadline}</h3>
      </div>
      <NavigationBar
        navItems={APP_CONFIG.navigationItems}
        categoryIndex={selectedCategoryIndex}
        categoryNavFn={selectCategoryFn}
      />
    </div>
  );
}

export default AppHeader;
